.operator__name {
  font-size: 16px;
}

.operator__country {
  font-size: 14px;
}

.operator__icon {
  width: 40px;
  height: 40px;
}