
.operators {
  margin: 34px 0 16px;
  transition: all .3s linear;
  opacity: 1;
}

.operators__hide {
  opacity: 0;
}

.operators__item {
  flex: 0 0 auto;
  width: 40px;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  background: rgba(217, 217, 217, 0.2);
  justify-content: center;
  border-radius: 9px;
  margin-right: 12px;
  transition: all .2s linear;
}

.operators__item img{
  height: 40px;
  width: 40px;
  border-radius: 9px;
}

.operators__item--dark {
  background: #FFFFFF;
}

.operators__item:last-child {
  margin-right: 0;
}


.hide {
  opacity: 0;
}