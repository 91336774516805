.contact__date {
  font-size: 14px;
}

.contact__phone{
  color: #222222;
  margin-bottom: 0;
}

.contact__phone--dark{
  color: #FFFFFF!important;
}

.contact__actions{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact__actions svg{
  margin-right: 26px;
}

.contact__actions svg:last-child{
  margin-right: 0;
}