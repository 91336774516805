body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--tg-theme-bg-color);
  width: 100% !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.blocked-scroll {
  overflow: hidden;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
  border: 3px solid transparent !important;
  background-color: rgba(255, 255, 255, 0.32549) !important;
  background-clip: content-box !important;
}