.language__btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  margin-bottom: 20px;
  background: rgba(217, 217, 217, 0.2);
  border: 1.4px solid transparent;
  border-radius: 10px;
  transition: all .2s linear;
  padding: 12px 0;
}

.language__btn:hover{
  background: #EDF6FD;
}

.language__btn:active{
  border-color: #50A7EA;
}

.language__btn--dark{
  background: rgba(5, 10, 16, 0.2);
}

.language__btn--dark:hover{
  background: rgba(80, 167, 234, 0.1);
}

.language__btn--img{
  width: 29px;
  height: 20px;
  position: relative;
}

.language__btn--dark:active{
  background: rgba(80, 167, 234, 0.10);
}

.language__btn--active{
  border-color: #50A7EA;
}


.language__btn--dark .language__btn--text{
  color: #FFFFFF;
}

.language__btn--text{
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;
  transition: all .2s linear;
}

.language__btn--active .language__btn--text{
  color: #3995D4;
}