.foreign__operators {
  margin: 0 auto;
  padding: 26px 16px;
}

.foreign__title {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.foreign__title--dark {
  color: #FFFFFF;
}

.foreign__operators-wrapper{
  overflow-y: auto;
  height: calc(var(--tg-viewport-stable-height) / 1.3);
}

.foreign__operators-wrapper::-webkit-scrollbar {
  display: none;
}