.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: var(--tg-viewport-stable-height);
  right: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 5000000;
  display: flex;
  align-items: center;
  justify-content: center;
}