.tabs {
  margin: 0 auto;
  padding: 0 0 26px;
  height: calc(var(--tg-viewport-stable-height) / 2.2);
  transition: all .4s ease-in-out;
}

.tabs__hide {
  height: calc(var(--tg-viewport-stable-height) / 1.55);
}

.ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs-content-holder {
  padding: 0 16px;
}

.ant-tabs-tab {
  flex: 0 0 50%;
  margin-left: 0 !important;
  justify-content: center;
  font-weight: 500;
}

.ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
  width: 50%;
}

.contact__item {
  height: 100%;
  padding: 0 0 12px 0;
  margin-top: 26px;
  transition: all .4s ease-in-out;
  overflow-y: auto;
}
.contact__scroll{
  min-height: 100%;
  padding: 20px 0;
  max-height: 100%;
}
.contact__item::-webkit-scrollbar, .contact__scroll::-webkit-scrollbar {
  display: none;
}



.ant-tabs-content-holder, .ant-tabs-content, .ant-tabs-tabpane {
  height: 100% !important;
}

