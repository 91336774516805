.divider {
    background: #F0F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.03);
    height: 11px;
    width: 100%;
    position: relative;
    margin: 0 0 20px;
transition: all .2s linear;
}

img{
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.infinite-scroll-component__outerdiv{
    height: 100%!important;
}

.infinite-scroll-component .contact__scroll{
    height: 100%!important;
    overflow-x: auto!important;
    overflow-y: scroll!important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    transition: background-color 5000s;
    -webkit-text-fill-color: #fff !important;
}

.divider__dark{
    background: #151E27;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.04);
}

.divider__no-bottom-margin{
    margin-bottom: 2px;
}