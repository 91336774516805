
.main {
  height: 285px;
  transition: height .4s ease-out, overflow .6s ease-in-out;
  margin: 26px auto 0;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


.transactions__main {
  height: calc(var(--tg-viewport-stable-height) / 2.32);
  margin: 26px auto;
  padding: 0 16px;
  transition: all .4s ease-in-out;
}

.transactions__main--hide{
  height: calc(var(--tg-viewport-stable-height) / 1.32);
}

.main__hidden {
  height: 64px;
  cursor: pointer;
  margin-bottom: 0;

}


.main__link {
  border: 1.4px solid #D9D9D9;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 13px;
  color: #222222;
  margin-left: auto;
  position: absolute;
  top: 0;
  right: 16px;
  text-transform: uppercase;
  transition: all .2s linear;
}

.main__link--dark {
  color: #FFFFFF;
  border-color: rgba(255,255,255, .2);
}

.main__text {
  text-align: center;
  font-weight: 500;
  opacity: 1;
  font-size: 14px;
  color: rgba(34, 34, 34, 0.7);
  transition: all .2s ease-in-out;
  visibility: hidden;
}

.main__text--dark {
  color: rgba(255, 255, 255, .7);
}

.main__operators {
  color: #3995D4;
}

.hide {
  opacity: 0;
}