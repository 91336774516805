.action__wrapper {
  margin-top: 60px;
transition: all .7s linear;
  padding: 0 16px;
  opacity: 1;
}

.action__wrapper--hide {
  opacity: 0;
}

.action__btn {
  flex: 0 0 48%;
  border-radius: 8px;
  padding: 5px 0 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  cursor: pointer;
  transition: all .2s linear;
  font-weight: 500;
  border: 1px solid transparent;
  font-size: 13px;

  p {
    margin-top: 5px;
  }
}


.action__btn--balance {
  background: #50A7EA;
  color: #FFFFFF;
}

.action__btn--balance:hover {
  color: #FFFFFF;
  background: #61B2F0;
}

.action__btn--balance:active {
  background: #3A9DE9;
}


.action__btn--contacts {
  background: #EDF6FD;
  color: #3995D4;
}

.action__icon {
  transition: all .2s linear;
}

.action__btn--contacts:hover {
  background: #DDF0FF;
  color: #3995D4;
}

.action__btn--contacts:active {
  background: #50A7EA;
  color: #FFFFFF;
}

.action__btn--contacts:active .action__icon {
  fill: #FFFFFF;
}

.action__btn--dark {
  background:rgba(80, 167, 234, 0.14);
}

.action__btn--dark:hover {
  background: rgba(80, 167, 234, 0.24);

}

.action__btn--dark:active {
  background: rgba(58, 157, 233, 1);
}

.action__btn--dark:active .action__icon {
  fill: #FFFFFF;
}


.hide {
  opacity: 0;
}