.payment-contact {
  margin: 0 auto 20px;
  padding: 26px 16px 0;
  transition: all .4s ease-in-out;
  height: 285px;
  overflow: hidden;

}

.payment-contact__unknown {
  height: 304px;
}

.payment-contact__hide {
  height: 145px;
}

.payment-contact__unknown--hide {
  height: 112px;
}

.payment-contact__icon {
  height: 80px;
  width: 80px;
  transition: all .4s ease-in-out;
  margin: 0 auto 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  font-weight: 500;
}

.payment-contact__icon--hide {
  height: 50px;
  width: 50px;
  font-size: 24px;
  margin-bottom: 10px;
}

.payment-contact__icon--full {
  border: 1px solid #D9D9D9;
  border-radius: 20px;
}

.payment-contact__icon--full img {
  height: 80px;
  width: 80px;
  border-radius: 20px;
  transition: all .4s ease-in-out;
}

.payment-contact__icon--hide img {
  height: 50px;
  width: 50px;
  border-radius: 20px;
}

.payment-contact__name {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  transition: all .4s ease-in-out;
}

.payment-contact__name--hide {
  font-size: 16px;
}

.payment-contact__phone {
  margin-top: 9px;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  margin-bottom: 53px;
  transition: all .4s ease-in-out;
  color: rgba(34, 34, 34, 0.7);
}

.payment-contact__phone--dark {
  color: rgba(255, 255, 255, .7);
}

.payment-contact__phone--hide {
  font-size: 14px;
}

.payment-contact__operator {
  border: 1px solid #D9D9D9;
  border-radius: 7px;
  margin-right: 6px;
  width: 26px;
  height: 26px;
  display: flex;
  background: #FFFFFF;
  align-items: center;
  justify-content: center;
}

.payment-contact__operator--dark {
  border-color: #4A525C;
}

.payment-contact__operator img {
  width: 26px;
  height: 26px;
  border-radius: 7px;
  transition: all .4s ease-in-out;
}

.payment-contact__pay {
  margin-top: 0;
  opacity: 1;
  transition: all .4s ease-in-out;
}

.payment-contact__pay--hide {
  opacity: 0;
}

.payment-contact__add {
  margin-bottom: 14px;
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  padding: 13px 0;
}