.contact__remove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 13px 0;
}

.contact__text {
  color: #222222;
  text-align: center;
  margin-bottom: 40px;
}

.contact__text span {
  font-weight: 600;
}

.contact__text--dark {
  color: #FFFFFF;
}