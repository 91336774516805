.name__input {
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 28px;
  background: none;
  transition: all .4s ease-in-out;
}

.name__input::placeholder{
  color: #A8A8A8;
}