.form {
  padding: 26px 16px 0;
  margin: 0 auto 20px;
  transition: all .4s ease-in-out;
  height: 325px;
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  overflow: hidden;
}

.form__hide {
  height: 190px;
}

.phone__wrapper {
  position: relative;
  transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  height: 86px;
}

.phone__wrapper--hide {
  height: 57.2px;
}

.phone__icon {
  border: 1px solid #D9D9D9;
  border-radius: 12px;
  flex: 0 0 auto;
  overflow: hidden;
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 14px;
  transition: all .4s ease-in-out;
}

.phone__icon img {
  transition: all .4s ease-in-out;
  height: 52px;
  border-radius: 12px;
  width: 52px;
}

.phone__icon--small {
  height: 40px;
  width: 40px;
  top: 8px;
}

.phone__icon--small img {
  height: 40px;
  width: 40px;
}

.phone__icon--dark {
  border-color: #343D47;
}

.form__label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #878C90;
  margin-bottom: 6px;
  transition: all .4s ease-in-out;

  svg {
    margin-left: 7px;
  }
}

.form__label--small {
  font-size: 12px;
}

.phone__container {
  border-bottom: 1.6px solid #D9D9D9;
  padding: 14px 0 14px 62px;
  transition: all .4s ease-in-out;
  position: relative;
}






.phone__container--small {
  padding: 8px 0 8px 50px;
}

.sum {
  display: flex;
  border-bottom: 1.6px solid #D9D9D9;
  padding: 0 8px 14px 8px;
  margin-top: 23px;
  align-items: center;
  transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  height: 49px;
}

.sum:focus-within, .phone__container:focus-within{
  border-color: #50A7EA;
}


.sum__hide{
  height: 39px;
}

.phone__input, .sum__input {
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 28px;
  background: none;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}


.sum__input {
  display: block;
  flex: 0 0 auto;
  transition: none;
  margin-right: 6px;
}


.phone__input--small {
  font-size: 16px !important;
}

.phone__input--dark {
  color: #FFFFFF;
}

.ant-drawer-content-wrapper, .ant-drawer-content {
  border-radius: 15px 15px 0px 0px;
}

.sum__currency {
  color: #A8A8A8;
  flex: 1 0 auto;
  font-size: 22px;
  font-weight: 500;
  transition: all .4s ease-in-out;
}

.sum__currency--small {
  font-size: 14px;
}


.refill__btn {
  width: 100%;
  margin-top: 39px;
  opacity: 1;
  transition: all .4s ease-in-out;
}

.refill__btn--hide {
  opacity: 0;
}


.error__color {
  color: #F56059;

}

.form__error {
  font-size: 13px;
  color: #F56059;
  opacity: 0;
  margin-top: 4px;
  min-height: 20px;
  transition: all .2s linear;
}

.form__error--small {
  font-size: 12px;
}

.form__error--show {
  opacity: .99;
}

.phone__container--dark, .sum__dark{
  border-color: rgba(255,255,255,.2);
}

.ant-drawer-header{
  border-bottom: 0!important;
}



.error__border {
  border-color: #F2625A!important;
}