.languages {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  transition: all .2s linear;
  margin: 0 auto;
  padding: 26px 16px;
}


.languages__info {
  transition: all .2s linear;
}




.languages__title--dark, .languages__subtitle--dark {
  color: #FFFFFF;
}

.languages__title {
  font-weight: 500;
  font-size: 18px;
  margin-left: 8px;
}

.languages__form {
  margin-top: 28px;
  padding: 0 14px;
}

.languages__subtitle {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  opacity: 0.7;
  margin-bottom: 24px;
}


.language__continue {
  background: #50A7EA;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  border: none;
  margin-top: calc(var(--tg-viewport-stable-height) - 400px);
  padding: 13px 0;
  cursor: pointer;
  transition: all .2s linear;
  width: 100%;
  text-decoration: none;
}


.language__continue:hover {
  background: #61B2F0;
}

.language__continue:active {
  background: #3A9DE9;
}

.language__continue--disabled {
  opacity: .3!important;
  pointer-events: none;
}


