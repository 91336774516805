.payment__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.6px solid #D9D9D9;
  margin-bottom: 14px;
  padding-bottom: 14px;
}

.payment__item:first-child {
  padding-top: 0;
}

.payment__date {
  font-size: 14px;
  color: rgba(34, 34, 34, 0.70);
}

.payment__date--dark{
  color: #7D8B98;
}

.payment__item-info{
  display: flex;
  align-items: center;

}

.payment__item--check{
  margin-left: 10px;
  background: #EDF6FD;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #50A7EA;
  border: none;
  font-size: 13px;
  padding: 8px;
  cursor: pointer;
  transition: all .2s linear;
}

.payment__item--check:hover {
  background: #DDF0FF;
}

.payment__item--check:active {
  background: #3A9DE9;
  color: #FFFFFF;
}

.payment__item--check-dark{
  background: rgba(80, 167, 234, 0.14);
}

.payment__item--check-dark:hover{
  background: rgba(80, 167, 234, 0.24);
}
.payment__item--check-dark:active{
  background: #3A9DE9;
}

.payment__sum{
  color: rgba(34, 34, 34, 0.70);

}