.toggler {
  background: #FFFFFF;
  border: 2px solid #E2F2FF;
  border-radius: 12px;
  box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  z-index: 15;
  transform: translateX(-50%);
  top: -17px;
}

.toggler__dark{
  background: #1D2733;
  border-color: #293F56;
  box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.1);
}

.toggler__icon {
  transition: all .3s linear;
}


.toggler__icon--reverse {
  transform: rotate(180deg);
}