.transactions{
  height: 100%;
}
.transactions__wrapper {
  overflow-y: auto;
  height: 100%;
}

.transactions__wrapper::-webkit-scrollbar {
  display: none;
}

.transactions__title {
  font-weight: 500;
  font-size: 14px;
  color: #878C90;
  transition: all .2s linear;
  margin-bottom: 22px;
}


.transactions__title--dark {
  color: rgba(255, 255, 255, .5);
}

.transactions__not-found {
  text-align: center;
}

.transactions__text {
  color: #878C90;
  transition: all .2s linear;
}

.transactions__text--dark {
  color: rgba(255, 255, 255, 0.5);
}