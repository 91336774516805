.search {
  margin: 19px 0 28px;

  position: relative;

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .search__clear {

    cursor: pointer;
  }
}

.search__small{
  margin-bottom: 0;
}

.search__input {
  width: 100%;
  margin-right: 10px;
  border: none;
  background: none;
  font-weight: 400;
  font-size: 16px;
  padding: 12px 12px 12px 0;
  outline: none;
  border-bottom: 1.6px solid #D9D9D9;
  border-radius: 0;
  transition: all .2s linear;
}

.search__input--dark {
  color: #FFFFFF;
  border-color: rgba(255,255,255,.2);
}

.search__input:focus {
  border-color: #50A7EA;
}

.search__input::-webkit-search-decoration,
.search__input::-webkit-search-cancel-button,
.search__input::-webkit-search-results-button,
.search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}