.layout {
  margin: 0 auto;
  color: #222222;
  transition: all .2s linear;
  overflow-x: hidden;
  height: 100%;
}

.layout__fixed{
  overflow: hidden;
}

.layout__dark {
  color: #FFFFFF;
}