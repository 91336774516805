
.refill__transactions{
  height: calc(var(--tg-viewport-stable-height) / 2.7);
  margin: 26px auto;
  padding: 0 16px;
  transition: all .4s ease-in-out;
}

.refill__transactions--hide{
  height: calc(var(--tg-viewport-stable-height) / 1.7);
}