.payment-result{
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  transition: all .2s linear;
  margin: 38px auto 0;
}

.payment-result__icon{
  margin: 0 auto 24px;
  text-align: center;
}

.payment-result__title{
  font-weight: 500;
  font-size: 24px;
  color: #2a2a2a;
  text-align: center;
  transition: all .2s linear;
  margin-bottom: 14px;
}

.payment-result__title--dark{
  color: #FFFFFF;
}

.payment-result__contact{
  display: flex;
  border: 1px solid #E9EAF0;
  border-radius: 14px;
  justify-content: space-between;
  padding: 14px;
  transition: all .2s linear;
  margin: 0 16px;
  position: relative;
}

.payment-result__contact--dark{
  border-color: #4A525C;
}


.payment-result__info{
  margin-bottom: 0;
}

.payment-result__info:after{
  display: none;
}


.payment-result__actions{
  transition: all .2s linear;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: calc(var(--tg-viewport-stable-height) - 416px);
  padding: 0 16px;
}

.payment-result__actions--small{
  margin-top: calc(var(--tg-viewport-stable-height) - 360px);

}

.payment-result__add{
  margin-top: 14px!important;
}

.payment__result-btn{
  background: #50A7EA;
  color: #ffffff;

}

.payment__result-btn:hover {
  background: #61B2F0;
  color: #FFFFFF;
}

.payment__result-btn:active {
  background: #3A9DE9;
}

.result__info--dark{
  border-color: rgba(74, 82, 92, 1);
}

.payment__receipt{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  cursor: pointer;
}

.payment__receipt--text{
  color: #50A7EA;
  font-size: 15px;
  margin-left: 7px;
  font-weight: 500;
}