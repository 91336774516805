.loader-skeleton{
  background: rgba(0, 0, 0, 0.06);
  border-radius: 9px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  right: 0;
}

.loader-skeleton .ant-skeleton-avatar{
  width: 100%!important;
  height: 100%!important;
  border-radius: 9px!important;
}
