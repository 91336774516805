
.transaction {
  margin-bottom: 14px;
}




.transaction__contact {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  flex: 0 0 auto;
  margin-right: 10px;
}

.transaction__icon {
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  flex: 0 0 auto;
  width: 35px;
  height: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.transaction__icon--dark {
  border-color: #4A525C;
}

.transaction__icon img {
  width: 35px;
  border-radius: 8px;
  height: 35px;
}

.transaction__info {
  flex: 1 0 auto;
  position: relative;
  transition: all .2s linear;
  margin-bottom: 14px;
}

.transaction__info:after{
  content: '';
  background: #D9D9D9;
  height: .6px;
  width: 100%;
  position: absolute;
  bottom: -12px;
  left: 0;
}

.transaction__info--dark:after {
  background: #050912;
}



.transaction__phone {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
  color: #222222;
  transition: all .2s ease-in-out;
}

.transaction__phone--dark {
  color: #FFFFFF;
}

.transaction__date {
  font-size: 12px;
  color: #999999;
  transition: all .2s ease-in-out;
}

.transaction__date--dark {
  color: #7D8B98;
}

.transaction__sum {
  font-weight: 600;
  font-size: 14px;
}

